import { useState, useEffect } from 'react';
import { Card, Table, Button, Modal, Box, Tag, Progress, Icon, Column } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import UserService from '../../../services/user';
import { PaginationCustom } from '../../shared/PaginationCustom';
import { Timer } from '../../shared/Timer';
import { getDiffTime, toNumber } from '../../../commons/utils';
import { toast } from 'react-toastify';
import { CardHeader } from '../../shared/CardHeader';
import { BoxHeader } from '../../shared/BoxHeader';
import { toCurrency } from '../../../helpers/toCurrency';

export const AppParkingHistoryList = ({ userData, goToMenu }) => {
  const [parkings, setParkings] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [activeProgress, setActiveProgress] = useState(true);
  const [activeModal, setActiveModal] = useState(false);
  const [selectedParking, setSelectedParking] = useState(null);

  const cadSpent = parkings.reduce((total, parking) => (total + parking?.cadSpent), 0)

  const cadRefunded = parkings.reduce((total, parking) => (total + parking?.cadRefunded), 0)

  useEffect(() =>{
    fetchParkingHistory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchParkingHistory = async () => {
    try {
      const response = await UserService.parkingHistory(userData.id);
      setParkings(response.data.data);
    } catch(e) {
      if (e.response.data)
        toast.error(e.response.data.message)
      else
        toast.error(e.response.message)
    }
    setActiveProgress(false);
  }

  const toggleModal = () => setActiveModal(!activeModal);

  const openModal = (index) => {
    toggleModal();
    setSelectedParking(index);
  }

  const onChangePage = (ppageOfItems) => {
    setPageOfItems(ppageOfItems);
  }

  const tableHeader = () => (
    <Table.Row>
      <Table.Heading>Placa</Table.Heading>
      <Table.Heading>Inicio</Table.Heading>
      <Table.Heading textAlign={'centered'}>CAD<br/>Utilizados</Table.Heading>
      <Table.Heading textAlign={'centered'}>CAD<br/>Restituídos</Table.Heading>
      <Table.Heading></Table.Heading>
    </Table.Row>
  )

  const tableItems = () => {
    return (pageOfItems.length === 0) ? (
      <Table.Row key={0}>
        <Table.Cell colSpan="9">
          { (activeProgress) ? <Progress size='small' color='custom-purple' /> : 'Não há histórico de uso para este usuário.' }
        </Table.Cell>
      </Table.Row>
    ) : (
      pageOfItems.map((parking, index) => (
        <Table.Row key={index}>
          <Table.Cell>{parking.licensePlate}</Table.Cell>
          <Table.Cell>{parking.startDate}</Table.Cell>
          <Table.Cell textAlign={'centered'}>{parking.cadsUsed}</Table.Cell>
          <Table.Cell textAlign={'centered'}>{parking.cadRefunded}</Table.Cell>
          <Table.Cell>
            <Icon size={'medium'} className='is-clickable ml-2' title='Detalhes' onClick={() => openModal(index)} >
              <FontAwesomeIcon icon={faInfoCircle} size='lg' />
            </Icon>
          </Table.Cell>
        </Table.Row>
      ))
    )
  }

  const itemDetail = () => {
    if (selectedParking !== null) {
      const parking = pageOfItems[selectedParking];
      return (
        <Box>
          <BoxHeader title='Detalhes do Estacionamento' handleClose={toggleModal} />
          <Column.Group className="mb-0" breakpoint='mobile'>
            <Column className="pb-0">
              <small><strong>Placa</strong></small><br />
              {parking?.licensePlate}
            </Column>
            <Column className="pb-0">
              <small><strong>Tipo do Veículo</strong></small><br />
              {parking.vehicleType.toUpperCase()}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <small><strong>Local</strong></small><br />
          {parking.addressName}


          <hr className="hr" />
          <Column.Group className="mb-0" breakpoint='mobile'>
            <Column className="pb-0">
              <small><strong>Regra estacionamento</strong></small><br />
              {parking.regionRule}
            </Column>
            <Column className="pb-0">
              <small><strong>Com renovação?</strong></small><br />
              {parking.renewed ? 'Sim' : 'Não'}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <Column.Group className="mb-0" breakpoint='mobile'>
            <Column className="pb-0">
              <small><strong>Início</strong></small><br />
              {parking.startDate}
            </Column>
            <Column className="pb-0">
              <small><strong>{ parking.end ? 'Fim' : 'Tempo restante' }</strong></small><br />
              {
                parking.end ? (
                  parking.endDate
                ) : (
                  <Tag color="custom-purple" size='medium'>
                    <Timer 
                      startDate={parking.start} 
                      paidMinutes={parking.paidTime} 
                      reverse={true} 
                    />
                  </Tag>
                )
              }
            </Column>
          </Column.Group>

          <hr className="hr" />
          <Column.Group className="mb-0" breakpoint='mobile'>
            <Column className="pb-0">
              <small><strong>Tempo estacionado</strong></small><br />
              {
                parking.end ? (
                  getDiffTime(parking.start, parking.end)
                ) : (
                  <Tag color="custom-purple" size='medium'>
                    <Timer 
                      startDate={parking.start} 
                      paidMinutes={parking.paidTime}
                    />
                  </Tag>
                )
              }
            </Column>
            <Column className="pb-0">
              <small><strong>CADs Utilizados</strong></small><br />
              {parking.cadsUsed} por {toCurrency(parking.moneySpent)}
            </Column>
          </Column.Group>

          <hr className="hr" />
          <Column.Group className="mb-0" breakpoint='mobile'>
            <Column className="pb-0">
              <small><strong>CADs Restituídos</strong></small><br />
              {parking.cadRefunded} por {toCurrency(parking.moneyRefunded)}
            </Column>
            <Column className="pb-0">
              <small><strong>Código Autenticação</strong></small><br />
              {parking.authenticateCode}
            </Column>
          </Column.Group>
        </Box>
      )
    }
  }

  return (
    <>
      <Card>
        <CardHeader handleClose={goToMenu}>Histórico de uso</CardHeader>
        <Card.Content>
          <strong>Resumo:</strong>
          <p className="mb-0">
            {toNumber(cadSpent)} utilizados - {toNumber(cadRefunded)} restituídos = {toNumber(cadSpent - cadRefunded)} usados
          </p>
          <hr className='hr my-2'/>
          <div className="table-container">
            <Table fullwidth>
              <Table.Head>
                { tableHeader() }
              </Table.Head>
              <Table.Body>
                { tableItems() }
              </Table.Body>
            </Table>
          </div>
          <div className="is-flex is-justify-content-space-between is-align-items-center">
            <Button type="button" onClick={goToMenu} color='danger' outlined>
              Fechar
            </Button>
            <PaginationCustom items={parkings} onChangePage={onChangePage} />
          </div>
        </Card.Content>
      </Card>

      <Modal closeOnBlur={true} closeOnEsc={true} active={activeModal} onClose={toggleModal}>
        <Modal.Background onClick={toggleModal} />
        <Modal.Content>
          { itemDetail() }
        </Modal.Content>
        <Modal.Close />
      </Modal>
    </>
  )
}
